@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	--dark-body-bg: rgba(11, 16, 29);
	--light-body-bg: rgb(240, 244, 255);
	--scroll-thumb-bg: #5e7eff;
	font-family: -apple-system, -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
body {
	overflow-x: hidden;
}
::-webkit-scrollbar {
	background: var(--light-body-bg);
	width: 10px;
}
html.dark ::-webkit-scrollbar {
	background: var(--dark-body-bg);
	width: 10px;
}
::-webkit-scrollbar-thumb {
	background-color: var(--scroll-thumb-bg);
	border-radius: 2rem;
	border: 3px solid var(--light-body-bg);
}
html.dark ::-webkit-scrollbar-thumb {
	background-color: var(--scroll-thumb-bg);
	border-radius: 2rem;
	border: 3px solid var(--dark-body-bg);
}
body {
	overflow-x: hidden !important;
	background: rgb(255, 255, 255);
	scroll-behavior: smooth;
}
.dark body {
	background: var(--dark-body-bg);
}

#spinner {
	height: 100vh;
	width: 100vw;
	background-color: #4d78ff;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
}

.waving-hand {
	animation-name: wave-animation;
	animation-duration: 2.5s;
	animation-iteration-count: infinite;
	transform-origin: 70% 70%;
	display: inline-block;
}

@keyframes wave-animation {
	0% {
		transform: rotate(0deg);
	}
	15% {
		transform: rotate(14deg);
	}
	30% {
		transform: rotate(-8deg);
	}
	40% {
		transform: rotate(14deg);
	}
	50% {
		transform: rotate(-4deg);
	}
	60% {
		transform: rotate(10deg);
	}
	70% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
